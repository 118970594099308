<template>
  <div class="offer-case" @click="goToCase">
    <UiImage class="offer-case__image" :src="image" />
    <div class="offer-case__content">
      <div class="offer-case__content-info">
        <span v-if="amount > 1" class="offer-case__content-info-amount">{{ amount }}</span>
        <span class="offer-case__content-info-name">{{ name }}</span>
      </div>
      <div class="offer-case__content-price">
        <BaseCurrency class="offer-case__content-price-old">{{ oldPriceFormatted }}</BaseCurrency>
        <span class="offer-case__content-price-new">{{ $t('secretShop.offer.free') }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IOfferCase } from '~/features/secret-shop/types/offers/client.types';

const props = defineProps<IOfferCase>();
const { slug, oldPrice } = toRefs(props);

const oldPriceFormatted = computed(() => getValueRoundedByCurrency(oldPrice.value));

const goToCase = () =>
  navigateTo(`/open/${slug.value}`, {
    open: {
      target: '_blank',
    },
  });
</script>

<style scoped lang="scss" src="./OfferCase.scss"></style>
