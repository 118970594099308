<template>
  <div class="offer-money">
    <UiImage class="offer-money__image" src="/static/main-page/event-ruble.png" />
    <div class="offer-money__content">
      <div class="offer-money__content-info">
        <BaseCurrency v-if="money" class="offer-money__content-info-money">{{ moneyFormatted }}</BaseCurrency>
        <span v-if="money" class="offer-money__content-info-plus">+</span>
        <div v-if="coins" class="offer-money__content-info-coins">
          <UiImage class="offer-money__content-info-coins-icon" :src="coinIcon" />
          {{ coins }}
        </div>
      </div>
      <span class="offer-money__content-bottom">{{ $t('secretShop.offer.onBalance') }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IOfferMoneyProps } from './OfferMoney.types';

const props = defineProps<IOfferMoneyProps>();
const { money } = toRefs(props);

const moneyFormatted = computed(() => getValueRoundedByCurrency(money.value ?? 0));
</script>

<style scoped lang="scss" src="./OfferMoney.scss"></style>
