import type { IOfferPrice, IOfferRestraints } from '~/features/secret-shop/types/offers/client.types';
import { ESharedKitProgressCounterTypes } from '~/components/SharedKitProgressCounter/SharedKitProgressCounter.types';

interface IOfferDataArgs {
  price: Ref<IOfferPrice>;
  restraints: Ref<IOfferRestraints>;
}

export const useOfferData = ({ price, restraints }: IOfferDataArgs) => {
  const priceData = computed(() => {
    const { old, new: newPrice } = price.value;
    const profit = Math.round((1 - newPrice / old) * 100);

    return {
      new: getValueRoundedByCurrency(newPrice),
      old: getValueRoundedByCurrency(old),
      profit: `- ${profit}%`,
    };
  });

  const counterData = computed(() => {
    const { counter, time } = restraints.value;

    if (time) {
      return {
        end: time.till,
        start: time.from,
        type: ESharedKitProgressCounterTypes.TIME,
      } as const;
    }

    return {
      initial: counter.max,
      current: counter.current,
      type: ESharedKitProgressCounterTypes.QUANTITY,
    } as const;
  });

  return {
    priceData,
    counterData,
  };
};
